import { React, useState, useEffect, useContext } from 'react';
import { WalletContext } from '../WalletContext';
import '../styles/UpdateVideo.css';

const UpdateVideo = () => {
    const [formAction, setFormAction] = useState('Add New Video');
    const [videoType, setVideoType] = useState('Tutorial Video');
    const [videoTitle, setVideoTitle] = useState('');
    const [videoDescription, setVideoDescription] = useState('');
    const [videoUrl, setVideoUrl] = useState('');
    const [nonceAndType, setNonceAndType] = useState([{ nonce: 1, type: 'Tutorial Video' }]);
    const [nonceFaq, setNonceFaq] = useState([{ nonce: 1, question: 'Sample FAQ Question' }]);
    const [selectedNonce, setSelectedNonce] = useState('');
    const [selectedFaqNonce, setSelectedFaqNonce] = useState('');
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const { isConnected, timeDateContract, account } = useContext(WalletContext);

    useEffect(() => {
        const fetchData = async () => {
            if (!isConnected || !timeDateContract || !account) {
                return;
            }
            try {
                const videoData = [];
                const faqData = [];
                const videoNonce = await timeDateContract.methods.videoNonce().call();
                const faqNonce = await timeDateContract.methods.faqNonce().call();
                const videoNonceString = parseInt(videoNonce.toString());
                const faqNonceString = parseInt(faqNonce.toString());
                console.log('Nonce', videoNonceString, faqNonceString);

                // Fetch video data
                for (let i = 1; i < videoNonceString; i++) {
                    const videoDataType = await timeDateContract.methods.getVideoData(i).call();
                    console.log('I and Video Type', i, videoDataType);
                    videoData.push({
                        nonce: Number(videoDataType.videoNonce),
                        type: videoDataType.types
                    });
                }

                // Fetch FAQ data
                for (let i = 1; i < faqNonceString; i++) {
                    const faqDataType = await timeDateContract.methods.getFaqData(i).call();
                    console.log('I and FAQ Type', i, faqDataType);
                    faqData.push({
                        nonce: Number(faqDataType.nonce),
                        question: faqDataType.question
                    });
                }

                console.log('Video Data:', videoData);
                console.log('FAQ Data:', faqData);

                setNonceAndType(videoData);
                setNonceFaq(faqData);
            } catch (error) {
                console.error('Error updating faq and video data', error);
            }
        };

        fetchData();
    }, [isConnected, timeDateContract, account]);

    const addNewVideo = async () => {
        if (!isConnected || !timeDateContract || !account) {
            alert("Please connect your wallet first.");
            return;
        }
        try {
            const videoTitles = [videoTitle];
            const videoDescriptions = [videoDescription];
            const videoUrls = [videoUrl];
            const videoTypes = [videoType];

            console.log("Adding new video...");
            const txHash = await timeDateContract.methods.addVideo(videoTitles, videoDescriptions, videoUrls, videoTypes).send({
                from: account
            });
            console.log("Video added successfully", txHash);
            alert("Video added successfully!");

            // Clear the form fields after successful submission
            setVideoTitle('');
            setVideoDescription('');
            setVideoUrl('');
        } catch (error) {
            console.error('Error adding new videos', error);
            alert("Error adding new video. Please try again.");
        }
    }

    const updateVideo = async () => {
        if (!isConnected || !timeDateContract || !account) {
            alert("Please connect your wallet first.");
            return;
        }
        try {
            console.log("Updating video...");
            const txHash = await timeDateContract.methods.updateVideo(selectedNonce, videoTitle, videoDescription, videoUrl, videoType, true).send({
                from: account
            });
            console.log("Video update successfully", txHash);
            alert("Video update successfully!");

            // Clear the form fields after successful submission
            setVideoTitle('');
            setVideoDescription('');
            setVideoUrl('');
        } catch (error) {
            console.error('Error to update videos', error);
            alert("Error to update video. Please try again.");
        }
    }

    const deleteVideo = async () => {
        if (!isConnected || !timeDateContract || !account) {
            alert("Please connect your wallet first.");
            return;
        }
        try {
            console.log("Deleting video...");
            const txHash = await timeDateContract.methods.updateVideo(selectedNonce, 'Nan', 'Nan', 'Nan', 'Nan', false).send({
                from: account
            });
            console.log("Video delete successfully", txHash);
            alert("Video delete successfully!");
        } catch (error) {
            console.error('Error to delete videos', error);
            alert("Error to delete video. Please try again.");
        }
    }

    const addFaq = async () => {
        if (!isConnected || !timeDateContract || !account) {
            alert("Please connect your wallet first.");
            return;
        }
        try {
            console.log("Adding new faq...");
            const questions = [question];
            const answers = [answer];
            const txHash = await timeDateContract.methods.addFAQ(questions, answers).send({
                from: account
            });
            console.log("FAQ update successfully", txHash);
            alert("Faq update successfully!");

            // Clear the form fields after successful submission
            setQuestion('');
            setAnswer('');
        } catch (error) {
            console.log('Error to add FAQ', error);
            alert("Error adding new FAQ. Please try again.");
        }
    }

    const updateFaq = async () => {
        if (!isConnected || !timeDateContract || !account) {
            alert("Please connect your wallet first.");
            return;
        }
        try {
            console.log("Updating faq...");
            const txHash = await timeDateContract.methods.updateFAQ(selectedFaqNonce, question, answer, true).send({
                from: account
            });
            console.log("Faq update successfully", txHash);
            alert("Faq update successfully!");

            // Clear the form fields after successful submission
            setQuestion('');
            setAnswer('');
        } catch (error) {
            console.error('Error updating faq', error);
            alert("Error updating faq. Please try again.");
        }
    }
    
    const deleteFaq = async () => {
        if (!isConnected || !timeDateContract || !account) {
            alert("Please connect your wallet first.");
            return;
        }
        try {
            console.log("Deliting faq...");
            const txHash = await timeDateContract.methods.updateFAQ(selectedFaqNonce, 'Nan', 'Nan', false).send({
                from: account
            });
            console.log("Faq deleted successfully", txHash);
            alert("Faq delete successfully!");
        } catch (error) {
            console.error('Error deleting faq', error);
            alert("Error deleting faq. Please try again.");
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission
        if (formAction === 'Add New Video') {
            await addNewVideo();
        } else if (formAction === 'Update Video') {
            await updateVideo();
        } else if (formAction === 'Add Faq') {
            await addFaq();
        } else if (formAction === 'Update Faq') {
            await updateFaq();
        } else if (formAction === 'Delete Faq') {
            await deleteFaq();
        } else if (formAction === 'Delete Video') {
            await deleteVideo();
        }
    }

    return (
        <div className='header-text'>
            <div className="video-form">
                <div className="form-container">
                    <div className="dropdown-container">
                        <select value={formAction} onChange={(e) => setFormAction(e.target.value)}>
                            <option value="Add New Video">Add New Video</option>
                            <option value="Update Video">Update Video</option>
                            <option value="Delete Video">Delete Video</option>
                            <option value="Add Faq">Add FAQ</option>
                            <option value="Update Faq">Update FAQ</option>
                            <option value="Delete Faq">Delete FAQ</option>
                        </select>
                        {(formAction === 'Add New Video' || formAction === 'Update Video') && (
                            <select value={videoType} onChange={(e) => setVideoType(e.target.value)}>
                                <option value="Tutorial Video">Tutorial Video</option>
                                <option value="Referral Video">Referral Video</option>
                                <option value="Reward Video">Reward Video</option>
                                <option value="Dashboard Video">Dashboard Video</option>
                                <option value="Ai Ultimate">Ai Ultimate</option>
                                <option value="Bitcoin Explorer">Bitcoin Explorer</option>
                                <option value="Blockchain Pro">Blockchain Pro</option>
                                <option value="Metaverse Master">Metaverse Master</option>
                            </select>
                        )}
                    </div>
                    {(formAction === 'Update Faq' || formAction === 'Delete Faq') && (
                        <div className="form-group">
                            <label>Select FAQ For Update</label>
                            <select className='select-dropdown'
                                value={selectedFaqNonce}
                                onChange={(e) => setSelectedFaqNonce(e.target.value)}
                            >
                                <option value="">Select The Faq</option>
                                {nonceFaq.map((item) => (
                                    <option key={item.nonce} value={item.nonce}>
                                        {item.nonce}. {item.question}
                                    </option>
                                ))}
                            </select>
                            {formAction === 'Delete Faq' && (<div className="submit-button">
                                <button onClick={handleSubmit}>
                                    Delete Faq
                                </button>
                            </div>)}
                        </div>
                    )}
                    {(formAction === 'Update Video' || formAction === 'Delete Video') && (
                        <div className="form-group">
                            <label>Select Video For Update</label>
                            <select className='select-dropdown'
                                value={selectedNonce}
                                onChange={(e) => setSelectedNonce(e.target.value)}
                            >
                                <option value="">Select The Video With Nonce</option>
                                {nonceAndType.map((item) => (
                                    <option key={item.nonce} value={item.nonce}>
                                        {item.nonce}. {item.type}
                                    </option>
                                ))}
                            </select>
                            {formAction === 'Delete Video' && (<div className="submit-button">
                                <button onClick={handleSubmit}>
                                    Delete Video
                                </button>
                            </div>)}
                        </div>
                    )}
                    {(formAction === 'Update Video' || formAction === 'Add New Video') && (
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>TITLE</label>
                                <input type="text" name="video-title" placeholder='Enter title' value={videoTitle} onChange={(e) => setVideoTitle(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>DESCRIPTION</label>
                                <input type="text" name="video-description" placeholder='Enter description' value={videoDescription} onChange={(e) => setVideoDescription(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>URL</label>
                                <input type='url' name="url" placeholder='Enter video url' value={videoUrl} onChange={(e) => setVideoUrl(e.target.value)} />
                            </div>
                            <div className="submit-button">
                                <button type="submit">
                                    {formAction === 'Add New Video' ? 'Add New Video' : 'Update Video'}
                                </button>
                            </div>
                        </form>
                    )}
                    {(formAction === 'Add Faq' || formAction === 'Update Faq') && (
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>Question</label>
                                <input type="text" name="question" placeholder='Enter question' value={question} onChange={(e) => setQuestion(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>Answer</label>
                                <input type="text" name="answer" placeholder='Enter answer' value={answer} onChange={(e) => setAnswer(e.target.value)} />
                            </div>
                            <div className="submit-button">
                                <button type="submit">
                                    {formAction === 'Add Faq' ? 'Add New Faq' : 'Update Faq'}
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UpdateVideo;