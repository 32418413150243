// App.js
import { React, useState } from 'react';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import { WalletProvider } from './WalletContext';
import Dashboard from './components/Dashbord';
import Transactions from './components/TransactionTable';
import ReferralNode from './components/ReferralNode';
import UserRankings from './components/UserRankings';
import UpdateVideo from './components/UpdateVideo';
import './App.css';

function App() {
  const [selectedItem, setSelectedItem] = useState('Dashboard');

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <WalletProvider>
      <div className="app" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <div style={{ display: 'flex', flex: 1 }}>
          <Sidebar onItemClick={handleItemClick} />
          <div className="main-content" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Header />
            <div style={{ flex: 1 }}>
              {selectedItem === 'Dashboard' && <Dashboard />}
              {selectedItem === 'Transactions' && <Transactions />}
              {selectedItem === 'Referral' && <ReferralNode />}
              {selectedItem === 'Top Sellers' && <UserRankings />}
              {selectedItem === 'FAQ & Videos' && <UpdateVideo />}
            </div>
          </div>
        </div>
      </div>
    </WalletProvider>
  );
};

export default App;