// WalletContext.js
import { React, createContext, useState } from 'react';

export const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [account, setAccount] = useState('');
  const [isConnected, setIsConnected] = useState('');
  const [web3, setWeb3] = useState('');
  const [isApproved, setApproved] = useState(false);
  const [contract, setContract] = useState('');
  const [bitcoinExplorerMember, setBitcoinExplorerMember] = useState('');
  const [blockchainProMember, setBlockchainProMember] = useState('');
  const [metaverseMasterMember, setMetaverseMasterMember] = useState('');
  const [aiUltimateProMember, setAiUltimateProMember] = useState('');
  const [nftContract, setNftContract] = useState('');
  const [usdtContract, setUsdtContract] = useState('');
  const [referee, setReferee] = useState('');
  const [milestonRewards, setMilestonRewards] = useState('');
  const [totalRefferals, setTotalRefferals] = useState('');
  const [totalEarning, setTotalEarning] = useState('');
  const [todaysEarning, setTodaysEarning] = useState('');
  const [thisMonthEarning, setThisMonthEarning] = useState('');
  const [thisYearEarning, setThisYearEarning] = useState('');
  const [itemSelected, setItemSelected] = useState('');
  const [timeDateAddress, setTimeDateAddress] = useState('');
  const [impactXAddress, setImpactXAddress] = useState('');
  const [tokenAddress, setTokenAddress] = useState('');
  const [nftTokenAddress, setNftTokenAddress] = useState('');
  const [timeDateContract, setTimeDateContract] = useState('');
  const [allUsers, setAllusers] = useState([]);
  const [todaysSell, setTodaysSell] = useState('');
  const [thisMonthSell, setThisMonthSell] = useState('');
  const [thisYearSell, setThisYearSell] = useState('');
  const [connectedChainId, setConnectedChainId] = useState();
  const [usdtDecimal, setUsdtDecimal] = useState();

  return (
    <WalletContext.Provider value={{
      todaysSell, setTodaysSell, thisMonthSell, setThisMonthSell, thisYearSell, setThisYearSell,
      account, setAccount, referee, setReferee, isConnected, setIsConnected,
      web3, setWeb3, contract, setContract,
      bitcoinExplorerMember, setBitcoinExplorerMember,
      blockchainProMember, setBlockchainProMember,
      metaverseMasterMember, setMetaverseMasterMember,
      aiUltimateProMember, setAiUltimateProMember,
      isApproved, setApproved, connectedChainId, setConnectedChainId,
      impactXAddress, setImpactXAddress,
      tokenAddress, setTokenAddress, usdtDecimal, setUsdtDecimal,
      nftTokenAddress, setNftTokenAddress,
      nftContract, setNftContract,
      usdtContract, setUsdtContract,
      milestonRewards, setMilestonRewards,
      totalRefferals, setTotalRefferals,
      thisMonthEarning, setThisMonthEarning,
      todaysEarning, setTodaysEarning, totalEarning, setTotalEarning,
      thisYearEarning, setThisYearEarning, itemSelected, setItemSelected,
      timeDateAddress, setTimeDateAddress, timeDateContract, setTimeDateContract,
      allUsers, setAllusers
    }}>
      {children}
    </WalletContext.Provider>
  );
};