import React, { useState, useEffect, useContext } from 'react';
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { formatEther } from 'ethers';
import { WalletContext } from '../WalletContext';
import '../styles/Dashboard.css';

const Dashboard = () => {
  const [chartData, setChartData] = useState([]);
  const [error, setError] = useState(null);
  const { web3, contract, allUsers, usdtDecimal } = useContext(WalletContext);

  useEffect(() => {
    const fetchData = async () => {
      if (web3 && contract) {
        try {

          let todaysSell = 0;
          let thisMonthSell = 0;
          let thisYearSell = 0;
          let overallSell = 0;
          let todaysUsers = 0;
          let thisMonthUsers = 0;
          let thisYearUsers = 0;
          const totalUsers = allUsers.length;

          console.log('going to fetch all user data');

          for (let i = 0; i < allUsers.length; i++) {
            try {
              const _todaysSell = await contract.methods.getTodaysEarning(allUsers[i]).call();
              const _thisMonthSell = await contract.methods.getThisMonthEarning(allUsers[i]).call();
              const _thisYearSell = await contract.methods.getThisYearEarning(allUsers[i]).call();
              
              todaysSell += parseInt(_todaysSell) / 10 ** usdtDecimal;
              thisMonthSell += parseInt(_thisMonthSell) / 10 ** usdtDecimal;
              thisYearSell += parseInt(_thisYearSell) / 10 ** usdtDecimal;
              
              if (parseInt(_todaysSell) > 0) todaysUsers++;
              if (parseInt(_thisMonthSell) > 0) thisMonthUsers++;
              if (parseInt(_thisYearSell) > 0) thisYearUsers++;
            } catch (err) {
              console.error(`Error fetching data for user ${allUsers[i]}:`, err);
            }
          }
          
          overallSell = todaysSell + thisMonthSell + thisYearSell;

          // Aggregate data
          const aggregatedData = [
            { period: 'Today', users: todaysUsers, totalAmount: todaysSell },
            { period: 'This Month', users: thisMonthUsers, totalAmount: thisMonthSell },
            { period: 'This Year', users: thisYearUsers, totalAmount: thisYearSell },
            { period: 'Overall', users: totalUsers, totalAmount: overallSell },
          ];

          console.log('Aggregate data', todaysSell, thisMonthSell, thisYearSell, overallSell);

          setChartData(aggregatedData);
          setError(null);
        } catch (error) {
          console.error('Error fetching data:', error);
          setError(error.message);
        }
      }
    };

    fetchData();
  }, [web3, contract]);

  if (error) {
    return <div className="error-message">Error: {error}</div>;
  }

  return (
    <div className="dashboard-container">
      <h2 className="dashboard-title">Referral Network Activity</h2>
      {chartData.length > 0 ? (
        <ResponsiveContainer width="100%" height={400}>
          <ComposedChart
            data={chartData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="period" />
            <YAxis yAxisId="left" label={{ value: 'Number of Users', angle: -90, position: 'insideLeft' }} />
            <YAxis yAxisId="right" orientation="right" label={{ value: 'Total Invest', angle: 90, position: 'insideRight' }} />
            <Tooltip />
            <Legend />
            <Bar yAxisId="left" dataKey="users" fill="#8884d8" name="Number of Users" />
            <Line yAxisId="right" type="monotone" dataKey="totalAmount" stroke="#82ca9d" name="Total Invest" />
          </ComposedChart>
        </ResponsiveContainer>
      ) : (
        <div className="loading-message">Loading data...</div>
      )}
    </div>
  );
};

export default Dashboard;